/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦创始股东团队销售折扣明细页面
 * @FilePath: /frontend/src/pages/rebate/shilishou/common/team-purch-detail/index.js
 */
import CompRebateLayoutOne from "@/components/rebate/comp-rebate-layout-one/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getTeamPurchDetail } from "@/service/rebate/shilishouRebate";
import RebateTable from "@/components/rebate/rebate-table/index.vue";
import EmptyList from "@/components/common/empty-list/index.vue";

export default {
  name: "team-purch-detial",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutOne,
    RebateTable,
    EmptyList,
  },

  data() {
    return {};
  },

  computed: {
    //  订单列表
    orderList() {
      const { rebateInfo } = this;
      if (rebateInfo) {
        return rebateInfo.detailList || [];
      }
      return [];
    },
  },

  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getTeamPurchDetail({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};
